import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { PublicRoutes } from "./features/public/routes";

const App = () => {
  return (
    <Router>
      <div>
        <section>
          <Routes>
            <Route path="/*" element={<PublicRoutes />}></Route>
          </Routes>
        </section>
      </div>
    </Router>
  );
};

export default App;
