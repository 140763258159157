const Home = () => {
  return (
    <div
      style={{
        backgroundImage: "url(/img/background.jpg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        zIndex: "-999",
      }}
    >
      <img
        src="/img/comingsoon.jpg"
        alt="Ducky Wakes - Coming soon"
        style={{
          width: "auto",
          height: "40vh",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          border: "5px solid black",
        }}
      />
    </div>
  );
};

export default Home;
